import axios from "axios";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import GaugeChart from "react-gauge-chart";
//import MapAPI from "./MapAPI";

const CctvForm = (props) => {
  // 부모와 자식... 부모가 누구지?
  const cctv_id = props.cctv_id;
  const formWidth = props.formWidth;

  // 변수의 상태로 렌더링 여부 결정
  const [cctvData, setCctvData] = useState({}); //
  const fetchData = async () => {
    let response = await axios.get(
      `https://cctvdb.codedbyjst.com/cctvInfo?cctv_id=${cctv_id}`
    );
    setCctvData(response.data); // cctv에 대한 정보 가져옴
  };

  // 이미지를 1분마다 받아오기 위해, 관련 parameter인 imgDate를 주기적으로 갱신합니다.
  const [imgDate, setImgDate] = useState(new Date()); // new Date...
  useEffect(() => {
    const interval = setInterval(() => {
      setImgDate(new Date());
      fetchData(); // 위에서 받아온 것도 1분마다 갱신되어야 함
    }, 1000 * 60);

    return () => clearInterval(interval); // unmount시 interval을 제거합니다.
  }, []);

  // 최초에 한 번 데이터를 받아옵니다.
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Form에 실시간으로 입력되는 데이터를 받아오기 위한 state입니다.
  const [formData, setFormData] = useState({
    cctv_id: cctv_id,
  });
  const updateFormData = (e) => {
    // 이벤트가 발생하였을 때 e를 넘겨 줌
    const key = e.target.name; // 이벤트에서 원하는 데이터 > name
    const value = e.target.value; // 이벤트에서 원하는 데이터 > value
    setFormData({ ...formData, [key]: value });
  };

  // DB단에 Form에 입력된 값으로 갱신을 요청하는 함수입니다.
  const updateDatabase = async () => {
    let url = "https://cctvdb.codedbyjst.com/cctvInfo/update?";
    // cctv_id 더하기
    url += `cctv_id=${formData.cctv_id}&`;
    // parameter 존재하는지 판별
    let isParameterExist = false;
    if ("location" in formData) {
      if (formData.location.length > 0) {
        url += `location=${formData.location}&`;
        isParameterExist = true;
      }
    }

    if ("description" in formData) {
      if (formData.description.length > 0) {
        url += `description=${formData.description}&`;
        isParameterExist = true;
      }
    }
    if ("people_cnt_boundary" in formData) {
      if (formData.people_cnt_boundary.length > 0) {
        url += `people_cnt_boundary=${formData.people_cnt_boundary}&`;
        isParameterExist = true;
      }
    }

    if ("latitude" in formData) {
      if (formData.latitude.length > 0) {
        url += `latitude=${formData.latitude}&`;
        isParameterExist = true;
      }
    }

    if ("longitude" in formData) {
      if (formData.longitude.length > 0) {
        url += `longitude=${formData.longitude}&`;
        isParameterExist = true;
      }
    }

    if (isParameterExist) {
      //
      url = url.slice(0, -1); // & 제거
    } else {
      window.alert("주어진 parameter가 없습니다.");
      return; // parameter 없으므로 그냥 탈출
    }
    try {
      await axios.get(url);
      window.location.reload(); // 현재 창 전체 강제 리로딩
    } catch {
      window.alert("잘못된 값이 입력되었습니다.");
    }
  };

  const [gauge, setGauge] = useState(0);
  useEffect(() => {
    if(typeof(cctvData.people_cnt) == "number" && typeof(cctvData.people_cnt_boundary) == "number") {
      setGauge((parseFloat(cctvData.people_cnt / cctvData.people_cnt_boundary)));
    }
  }, [cctvData]);

  


  const [message, setMessage] = useState(0);
  useEffect(() => {
    // if((cctvData.people_cnt / cctvData.people_cnt_boundary) * 100 <= 25) {
    //   setMessage(cctvData.people_cnt / cctvData.people_cnt_boundary);
    // } else if((cctvData.people_cnt / cctvData.people_cnt_boundary) * 100 <= 75) {
    //   setMessage(cctvData.people_cnt / cctvData.people_cnt_boundary);
    // } else {
    //   setMessage(cctvData.people_cnt / cctvData.people_cnt_boundary);
    // }
    setMessage(((cctvData.people_cnt / cctvData.people_cnt_boundary) * 100).toFixed(1));
  }, [cctvData]);

  const location = [];
  const [color, setColor] = useState({ color: "black" });
  useEffect(() => {
    if ((cctvData.people_cnt / cctvData.people_cnt_boundary) * 100 <= 25) {
      setColor({ color: "green" });
    } else if (
      (cctvData.people_cnt / cctvData.people_cnt_boundary) * 100 <= 75) {
      setColor({ color: "yellow" });
    } else {
      setColor({ color: "red" });
    }
  }, [cctvData]);

  


  return (
    <div
      className="p-3 mb-3"
      cctv_id={cctv_id}
      style={{ width: `${formWidth}px` }}
    >
      <img
        src={`https://cctvdb.codedbyjst.com/download_image/s3/${cctv_id}?imgDate=${imgDate}`}
        alt="cctv_image"
        style={{ maxWidth: "100%" }}
      />

      {/* <MapAPI />  */}

      <GaugeChart
        id="gauge-chart2"
        nrOfLevels={420}
        arcsLength={[0.33, 0.34, 0.33]}
        colors={["#5BE12C", "#F5CD19", "#EA4228"]}
        percent={gauge}
        arcPadding={0.02}
      />

      <h1 id="message" align="center" style={color}>
        {" "}
        {`${message}%`}{" "}
      </h1>
      <Form id={`${cctvData.cctv_id}_Form`} onSubmit={updateDatabase}>
        <Form.Group className="mb-1">
          <Form.Label>description</Form.Label>
          <Form.Control
            name="description"
            placeholder={cctvData.description}
            onChange={updateFormData}
          />
          <Form.Text>cctv의 세부 위치 및 설명</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>last_updated</Form.Label>
          <Form.Control
            name="last_updated"
            placeholder={cctvData.last_updated}
            disabled
          />
          <Form.Text>최종 갱신 시간</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>people_cnt</Form.Label>
          <Form.Control
            name="people_cnt"
            placeholder={cctvData.people_cnt}
            disabled
          />
          <Form.Text>현재 사람 수</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>people_cnt_boundary</Form.Label>
          <Form.Control
            name="people_cnt_boundary"
            placeholder={cctvData.people_cnt_boundary}
            onChange={updateFormData}
          />
          <Form.Text>포화 기준 인원수</Form.Text>
        </Form.Group>
      </Form>
    </div>
  );
};

export default CctvForm;
