import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import apiClient from "../../Amplify/apiClient";

import CctvForm from "./CctvForm";

//Amplify[Auth]
import { useAuthenticator } from "@aws-amplify/ui-react";

const Admin = () => {
  // Amplify Auth
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  console.log(user);

  // location parsing
  const location = useParams().location;

  const [cctvIdList, setCctvIdList] = useState([]);
  /* API 서버에서 해당 location에 위치한 cctv들의 cctv_id 목록을 가져옵니다. */
  const fetchCctvIdList = async (location) => {
    let response = await apiClient.get(
      `https://cctvdb.codedbyjst.com/cctvInfo?location=${location}`
    );

    let idList = [];
    response.data.forEach((item, idx) => {
      idList.push(item.cctv_id);
    });
    setCctvIdList(idList);
  };

  useEffect(() => {
    fetchCctvIdList(location);
  }, [location]);

  /* cctvForm의 width를 정할때 특정 component(Container)의 width를 이용할 것입니다. */
  /* 이때 Container의 width값은 render된 후에 알 수 있고, 해당 값을 저장할 수 있는 변수(formWidth)가 필요하므로 */
  /* useEffect함수와 useState함수를 이용합니다. */
  const containerRef = useRef(null); // Container에 대한 Reference
  const [formWidth, setFormWidth] = useState(0);
  const updateFormWidth = () => {
    const ContainerWidth = containerRef.current.clientWidth;
    let new_formWidth;
    if (ContainerWidth > 900) {
      new_formWidth = ContainerWidth / 3;
    } else if (ContainerWidth > 600) {
      new_formWidth = ContainerWidth / 2;
    } else {
      new_formWidth = ContainerWidth;
    }
    setFormWidth(new_formWidth);
  };
  // resize 이벤트가 발생할때마다 갱신
  useEffect(() => {
    window.addEventListener("resize", updateFormWidth);
  }, []);
  // Contatiner의 정보가 변동되면 갱신
  useEffect(() => {
    updateFormWidth();
  }, [containerRef]);

  return (
    <div style={{ width: "100%" }}>
      <div>Navbar here</div>
      <button onClick={signOut}>Sign out</button>
      <Container fluid="md" ref={containerRef}>
        <Row>
          {cctvIdList.map((cctv_id) => (
            <CctvForm key={cctv_id} cctv_id={cctv_id} formWidth={formWidth} />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
