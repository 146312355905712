import apiClient from "../../Amplify/apiClient";
import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import LogDataChart from "./LogDataChart";

const CctvForm = (props) => {
  const cctv_id = props.cctv_id;
  const formWidth = props.formWidth;

  // 각 테이블의 데이터를 DB에서 가져옵니다.
  const [cctvInfo, setCctvInfo] = useState({});
  const [logData, setLogData] = useState([]);
  const fetchData = async () => {
    let response;
    response = await apiClient.get(
      `https://cctvdb.codedbyjst.com/cctvInfo?cctv_id=${cctv_id}`
    );
    setCctvInfo(response.data);
    response = await apiClient.get(
      `https://cctvdb.codedbyjst.com/logData?cctv_id=${cctv_id}`
    );
    setLogData(response.data);
  };

  // 이미지 및 데이터를 1분마다 받아오기 위해,
  // 이미지 관련 parameter인 imgDate를 주기적으로 갱신하고 fetchData()를 call해 데이터를 받아옵니다.
  const [imgDate, setImgDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setImgDate(new Date()); // 이미지 갱신
      fetchData(); // 데이터 갱신
    }, 1000 * 60);

    return () => clearInterval(interval); // unmount시 interval을 제거합니다.
    // eslint-disable-next-line
  }, []);

  // 최초에 한 번 데이터를 받아옵니다.
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Form에 실시간으로 입력되는 데이터를 받아오기 위한 state입니다.
  const [formData, setFormData] = useState({
    cctv_id: cctv_id,
  });
  const updateFormData = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  /* 업데이트 기능 */
  // DB단에 Form에 입력된 값으로 갱신을 요청하는 함수입니다.
  const updateDatabase = async () => {
    let url = "https://cctvdb.codedbyjst.com/cctvInfo/update?";
    // cctv_id 더하기
    url += `cctv_id=${formData.cctv_id}&`;
    // parameter 존재하는지 판별
    let isParameterExist = false;
    if ("location" in formData) {
      if (formData.location.length > 0) {
        url += `location=${formData.location}&`;
        isParameterExist = true;
      }
    }
    if ("description" in formData) {
      if (formData.description.length > 0) {
        url += `description=${formData.description}&`;
        isParameterExist = true;
      }
    }
    if ("people_cnt_boundary" in formData) {
      if (formData.people_cnt_boundary.length > 0) {
        url += `people_cnt_boundary=${formData.people_cnt_boundary}&`;
        isParameterExist = true;
      }
    }
    if ("latitude" in formData) {
      if (formData.latitude.length > 0) {
        url += `latitude=${formData.latitude}&`;
        isParameterExist = true;
      }
    }
    if ("longitude" in formData) {
      if (formData.longitude.length > 0) {
        url += `longitude=${formData.longitude}&`;
        isParameterExist = true;
      }
    }
    if (isParameterExist) {
      url = url.slice(0, -1); // & 제거
    } else {
      window.alert("주어진 parameter가 없습니다.");
      return; // parameter 없으므로 그냥 탈출
    }
    try {
      await apiClient.get(url);
      window.location.reload(); // 현재 창 전체 강제 리로딩
    } catch {
      window.alert("잘못된 값이 입력되었습니다.");
    }
  };

  /* 삭제 기능 */
  // Modal로 한 번 더 경고합니다.
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteShow = () => setDeleteShow(true); // show deleteShow Modal
  const handleDeleteClose = () => setDeleteShow(false); // hide deleteShow Modal
  // DB단에 현재 cctv_id를 갖는 row를 삭제하는 함수입니다.
  const deleteDatabase = async () => {
    let url = `https://cctvdb.codedbyjst.com/cctvInfo/delete?cctv_id=${cctv_id}`;
    await apiClient.get(url);
    window.location.reload(); // 현재 창 전체 강제 리로딩
  };

  return (
    <div
      className="p-3 mb-3"
      cctv_id={cctv_id}
      style={{ width: `${formWidth}px` }}
    >
      <img
        src={`https://cctvdb.codedbyjst.com/download_image/s3/${cctv_id}?imgDate=${imgDate}`}
        alt="cctv_image"
        style={{ maxWidth: "100%" }}
      />

      <LogDataChart logData={logData} />

      <Form id={`${cctvInfo.cctv_id}_Form`} onSubmit={updateDatabase}>
        <Form.Group className="mb-1">
          <Form.Label>cctv_id</Form.Label>
          <Form.Control
            name="cctv_id"
            placeholder={cctvInfo.cctv_id}
            disabled
          />
          <Form.Text>cctv의 고유 ID</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>location</Form.Label>
          <Form.Control
            name="location"
            placeholder={cctvInfo.location}
            onChange={updateFormData}
          />
          <Form.Text>cctv의 위치(기업명)</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>description</Form.Label>
          <Form.Control
            name="description"
            placeholder={cctvInfo.description}
            onChange={updateFormData}
          />
          <Form.Text>cctv의 세부 위치 및 설명</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>last_updated</Form.Label>
          <Form.Control
            name="last_updated"
            placeholder={new Date(cctvInfo.last_updated)}
            disabled
          />
          <Form.Text>최종 갱신 시간</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>people_cnt</Form.Label>
          <Form.Control
            name="people_cnt"
            placeholder={cctvInfo.people_cnt}
            disabled
          />
          <Form.Text>현재 사람 수</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>people_cnt_boundary</Form.Label>
          <Form.Control
            name="people_cnt_boundary"
            placeholder={cctvInfo.people_cnt_boundary}
            onChange={updateFormData}
          />
          <Form.Text>포화 기준 인원수</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>latitude</Form.Label>
          <Form.Control
            name="latitude"
            placeholder={cctvInfo.latitude}
            onChange={updateFormData}
          />
          <Form.Text>cctv의 위치(위도)</Form.Text>
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label>longitude</Form.Label>
          <Form.Control
            name="longitude"
            placeholder={cctvInfo.longitude}
            onChange={updateFormData}
          />
          <Form.Text>cctv의 위치(경도)</Form.Text>
        </Form.Group>
      </Form>

      <Stack direction="horizontal" gap={2} className="col-md-8 mx-auto">
        {/* 삭제 버튼 */}
        <Button variant="danger" onClick={handleDeleteShow}>
          CCTV 삭제
        </Button>
        <Modal show={deleteShow} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>CCTV 삭제 확인</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            해당 CCTV와 연결된 정보를 서버에서 삭제하시겠습니까?
            <br />
            해당 작업은 영구적이며 돌이킬 수 없습니다.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              취소
            </Button>
            <Button variant="danger" onClick={deleteDatabase}>
              CCTV 삭제
            </Button>
          </Modal.Footer>
        </Modal>

        {/* 저장 버튼 */}
        <Button variant="primary" onClick={updateDatabase}>
          변경사항 저장
        </Button>
      </Stack>
    </div>
  );
};

export default CctvForm;
