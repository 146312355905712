import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFoundPage from "./components/common/NotFoundPage";
import User from "./components/user/User";
import AdminPage from "./components/admin/AdminPage";
import MapAPI from "./components/user/MapAPI"

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// Amplify(Authentication)
import { Amplify } from "aws-amplify";
import awsconfig from "./Amplify/aws-exports";
Amplify.configure(awsconfig);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* 404 Error(PageNotFound) 에러 핸들링 */}
        <Route path="*" element={<NotFoundPage />} />

        {/* 사용자 페이지(임시적으로 tukorea로 설정)로 이동 */}
        { <Route path="/user" element={<Navigate to="/user/tukorea" />} /> }

        {/* 사용자 페이지 */}
        <Route path="/user/:location" element={<User />} />

        {/* 관리자 페이지(임시적으로 tukorea로 설정)로 이동 */}
        {/* <Route path="/admin" element={<Navigate to="/admin/tukorea" />} /> */}

        {/* 관리자 페이지 */}
        <Route path="/admin/:location" element={<AdminPage />} />

        {/* 관리자 페이지 */}
        <Route path="/user/map" element={<MapAPI/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
