import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axios from "axios";
import CctvForm from "./CctvForm";

const User = () => {
  const location = useParams().location; // location = tukorea

  

  const [cctvIdList, setCctvIdList] = useState([]);
  /* API 서버에서 해당 location에 위치한 cctv들의 cctv_id 목록을 가져옵니다. */
  const fetchCctvIdList = async (location) => {
    let response = await axios.get(
      `https://cctvdb.codedbyjst.com/cctvInfo?location=${location}`
    );

    let idList = [];
    response.data.forEach((item, idx) => {
      idList.push(item.cctv_id);
    });
    setCctvIdList(idList);
  };

  const containerRef = useRef(null);
  const [formWidth, setFormWidth] = useState(0);
  const updateFormWidth = () => { // container > 마진 사용, 당장 폭이 어떻게 될지 모름 > useRef 이용해서 참조
    const ContainerWidth = containerRef.current.clientWidth;
    
    let new_formWidth;
    if (ContainerWidth > 900) {
      new_formWidth = ContainerWidth / 3;
    } else if (ContainerWidth > 600) {
      new_formWidth = ContainerWidth / 2;
    } else {
      new_formWidth = ContainerWidth;
    }
    setFormWidth(new_formWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateFormWidth);
  }, []);

  useEffect(() => {
    // 코드 안에 있는 부분이 성립될 때 업데이트 됨
    fetchCctvIdList(location);
  }, [location]); // location이 변경되었을 때 실행함

  // Contatiner의 정보가 변동되면 갱신
  useEffect(() => { // 랜더링 > ref 수정 > 그때 width 가져옴
    updateFormWidth();
  }, [containerRef]);



  return (
    //
    <>
      <Container fluid="md" ref={containerRef}>
        <Row>
          {cctvIdList.map(
            (
              cctv_id // 리스트에서 요소를 가져와서 하나씩
            ) => (
              <CctvForm key={cctv_id} cctv_id={cctv_id} formWidth={formWidth}/> //  User가 Cctvform의 부모
            )
          )}
        </Row>
      </Container>
    </>
  );
};

export default User;
