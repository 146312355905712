import axios from "axios";
import { Auth } from "aws-amplify";

// Authorization 헤더 설정을 자동화하기 위해, interceptor를 생성합니다.
const apiClient = axios.create({ withCredentials: false });
apiClient.interceptors.request.use(async (config) => {
  const cognitoToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  config.headers["Authorization"] = `Bearer ${cognitoToken}`;
  return config;
});

export default apiClient;
