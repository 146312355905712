//Amplify(Auth)
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Admin from "./Admin";

const AdminPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Authenticator hideSignUp={true}>
        {({ signOut, user }) => <Admin />}
      </Authenticator>
    </div>
  );
};

export default AdminPage;
