import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import axios from 'axios'
import { useNavigate, BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const MyComponent = () => {

  //변수의 상태로 렌더링 여부 결정
  const [latitudeData, setLatitudeData] = useState({}); // 왜 함수 이름 바꾸었을 때 안 될까
  const [longitudeData, setLongitudeData] = useState({});
  const [densityData, setDensityData] = useState({});
  const [cctvNameData, setcctvNameData] = useState({});
  const fetchData = async () => {
    let response = await axios.get(
      `https://cctvdb.codedbyjst.com/cctvInfo`
    );

    let latitudeList = [];
    response.data.forEach((item, idx) => {
      latitudeList.push(item.latitude);
    });
    setLatitudeData(latitudeList);

    let longitudeList = [];
    response.data.forEach((item, idx) => {
      longitudeList.push(item.longitude);
    });
    setLongitudeData(longitudeList);

    let densityList = [];
    response.data.forEach((item, idx) => {
      densityList.push((item.people_cnt)/(item.people_cnt_boundary));
    });
    setDensityData(densityList);

    let cctvNameList = [];
    response.data.forEach((item, idx) => {
      cctvNameList.push((item.location));
    });
    setcctvNameData(cctvNameList);
  };



  //const [location, setLocation] = useState({lat: 37.566742, lng: 126.978253}); // 서울시청 좌표를 기본값으로 설정
  const location = [];

  console.log(densityData);

  for(var i = 0; i < 12; i++) {
    if(typeof(latitudeData[i]) == "number" && typeof(longitudeData[i]) == "number" && typeof(densityData[i]) == "number") {
      if((densityData[i] * 100) <= 25) {
        location.push ({
          position: {
          lat: parseFloat(latitudeData[i]),
          lng: parseFloat(longitudeData[i]),
          },
          color: 'https://ifh.cc/g/L3q1FX.png',
          id: cctvNameData[i]
        },);
      }
      else if ((densityData[i] * 100) <= 75) {
        location.push ({
          position: {
          lat: parseFloat(latitudeData[i]),
          lng: parseFloat(longitudeData[i]),
          },
          color: 'https://ifh.cc/g/YN74LO.png',
          id: cctvNameData[i]
        },);
      }
      else {
        location.push ({
          position: {
          lat: parseFloat(latitudeData[i]),
          lng: parseFloat(longitudeData[i]),
          },
          color: 'https://ifh.cc/g/gm3YDF.png',
          id: cctvNameData[i]
        },);
      }
    }

    
    
  }

  
  // 최초에 한 번 데이터를 받아옵니다.
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC1uviSMSlf9qEVfixSkbJZ6MQWyjM9hmw"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(); // 좌표 경계 정의
    // map.fitBounds(bounds);
    //location.forEach(({ position }) => bounds.extend(position));
    map.setZoom(18);
    setMap(map)
  }, [])





  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const navigate = useNavigate;
  const clickName = (id) => {
    // navigate('/user/tukorea');
    window.location.replace(`/user/${id}`)
  }



  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={20}
        center={{lat: 37.338667, lng: 126.734879}}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {location.map(({ id, color, position }) => (
        <Marker
          position={position}
          icon={color}
          onClick={() => clickName(id)}
        >
        </Marker>
      ))}
      
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)