import { useEffect, useState } from "react";
import { Chart as ChartJS, TimeScale } from "chart.js/auto";
import "chartjs-adapter-date-fns";
import Zoom from "chartjs-plugin-zoom";
import { ko } from "date-fns/locale";
import { Line } from "react-chartjs-2";
ChartJS.register(TimeScale, Zoom);

const LogDataChart = (props) => {
  // 상위 컴퍼넌트로부터 logData를 전달받습니다.
  const logData = props.logData;

  // logData에서 chart를 그리기 위해 필요한 데이터들을 추출합니다.
  const [labelList, setLabelList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const parseChartData = () => {
    let label_list = [];
    let data_list = [];

    logData.forEach((data, idx) => {
      label_list.push(new Date(data["update_time"]));
      data_list.push(data["people_cnt"]);
    });

    setLabelList(label_list);
    setDataList(data_list);
  };
  // logData가 변경될때마다 각 리스트를 갱신합니다.
  useEffect(() => {
    parseChartData();
    // eslint-disable-next-line
  }, [logData]);

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        adapters: {
          date: {
            locale: ko,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "밀집도 기록",
      },
      // chartjs-plugin-zoom
      // Guide : https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html
      zoom: {
        pan: { enabled: true, mode: "x" },
        limits: {
          x: { max: labelList[0], min: labelList[labelList.length - 1] },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: false,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
  };

  const chartData = {
    labels: labelList,
    datasets: [
      {
        label: "측정 인원수",
        data: dataList,
      },
    ],
  };

  return <Line options={chartOptions} data={chartData} height={"200px"} />;
};

export default LogDataChart;
